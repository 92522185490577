import React, {useEffect, useRef} from "react";
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import SEO from "../components/SEO";
import localize from "../components/localize";
import axios from 'axios';


export const query = graphql`
    {
  allSanitySettings {
    edges {
      node {
          telefon
          sociallinks {
              value
              type
              title
          }
          email
          address_street
          address_plz
          address_name
          address_nr
          address_ort
      }
    }
  }
    site {
      siteMetadata {
        lang{
        _type
        en
        de
        }
        work{
        _type
        en
        de
        }
        aboutme{
        _type
        en
        de
        }
        contact{
        _type
        en
        de
        }
        request{
            _type
            en
            de
        }message{
              _type
              en
              de
          }absenden{
              _type
              en
              de
          }
          error{
              _type
              en
              de
          }fillin{
              _type
              en
              de
          }success{
              _type
              en
              de
          }
        }
        }
        allSanityImpressum{
    edges {
      node {
      title{
            _type
            en
            de
        }
      }
      }
    }
    allSanityDatenschutz{
    edges {
      node {
      title{
            _type
            en
            de
        }
      }
      }
        
    }
    allSanityStartseite{
        edges{
            node{
                seo{
                    seo_title{
                        _type
                        en
                        de
                    }
                    seo_image {asset{gatsbyImageData}}
                    seo_description{
                        _type
                        en
                        de
                    }
                }
                servicesheadline{
            _type
            en
            de
        }
        aboutheadline{
            _type
            en
            de
        }
        photoheadline{
            _type
            en
            de
        }
        videoheadline{
            _type
            en
            de
        }
        factsheadline{
            _type
            en
            de
        }
        
            }
        }
    }
    }`;

const ContactPage = ({data, location}) => {

    const settings = data.allSanitySettings.edges[0].node;
    const startseite = data.allSanityStartseite.edges[0].node;

    const metadata = data.site.siteMetadata;

    const [valueKontakt, setValueKontakt] = React.useState({})

    let serverResponse = useRef();

    function handleEmptyField(e){
        if(e.target.value === ""){
            if(e.target.classList.contains('empty')){
                e.target.classList.remove('empty');
            }
        }
        else{
            if(!e.target.classList.contains('empty')){
                e.target.classList.add('empty');
            }
        }
    }
    function handleEmptyTextarea(e){

        if(e.target.innerText === ""){
            if(e.target.classList.contains('empty')){
                e.target.classList.remove('empty');
            }
        }
        else{
            if(!e.target.classList.contains('empty')){
                e.target.classList.add('empty');
            }
        }
    }


    function handleChange(e) {
       handleEmptyField(e);
        valueKontakt[e.target.id] = e.target.value;
        serverResponse.current.style.color = "#000000";
        serverResponse.current.innerText = "";
        setValueKontakt({...valueKontakt});
    }

    async function onSubmitKontakt(e) {
        e.preventDefault()
        if (valueKontakt.kontaktname && valueKontakt.kontaktemail) {

            valueKontakt.kontaktnachricht = document.querySelector('#kontaktnachricht').innerText;

            var params = new URLSearchParams();
            params.append('name', valueKontakt.kontaktname);
            params.append('email', valueKontakt.kontaktemail);
            params.append('nachricht', valueKontakt.kontaktnachricht);

            axios.post('https://api.fleurdenuit.at/form-ijob.php', params)
                .then(function (response) {
                    serverResponse.current.style.color = "#64C028";
                    serverResponse.current.innerText = metadata.success;
                    console.log(response);
                    setValueKontakt({});
                    if(document.querySelector('#kontaktemail').classList.contains("empty")){
                        document.querySelector('#kontaktemail').classList.remove("empty");
                    }
                    if(document.querySelector('#kontaktname').classList.contains("empty")){
                        document.querySelector('#kontaktname').classList.remove("empty");
                    }

                    document.querySelector('#kontaktnachricht').innerText = "";
                    if(document.querySelector('#kontaktnachricht').classList.contains("empty")){
                        document.querySelector('#kontaktnachricht').classList.remove("empty");
                    }
                })
                .catch(function (error) {
                    serverResponse.current.style.color = "#F05353";
                    serverResponse.current.innerText = metadata.error;
                    console.log(error);
                });
        } else {
            serverResponse.current.innerText = metadata.fillin;
            serverResponse.current.style.color = "#F05353";
        }
    }

    useEffect(() => {
        if(location.state?.betreff){
            document.querySelector('#kontaktnachricht').innerText = metadata.request + ' ' + location.state.betreff + " Service";
            document.querySelector('#kontaktnachricht').classList.add('empty');
        }
    })


    return (
        <Layout settings={settings} location={location} metadata={metadata} servicesheadline={startseite.servicesheadline} videoheadline={startseite.videoheadline} photoheadline={startseite.photoheadline} aboutheadline={startseite.aboutheadline} lang={metadata.lang} animated={false} privacy={data.allSanityDatenschutz.edges[0].node.title} imprint={data.allSanityImpressum.edges[0].node.title}>
            <SEO title={startseite.seo?.seo_title} description={startseite.seo?.seo_description} image={startseite.seo?.seo_image} location={location}></SEO>
            <div className="contact__outer privacy bg-carbon color-light">
                <div className="contact__inner">
                    <div className="animation__outer animation__outer__headline h1-animated-alt">
                        <h1>{metadata.contact}</h1>
                    </div>

                    <form onSubmit={onSubmitKontakt} className={"kontakt__form form div-animated"} method={"post"} action={"/api/form.php"}>
                        <div className="div-animated">
                            <div className="kontaktfield__container animated">
                                <input type="text" name={"kontaktname"} className={""} autoComplete={"off"} id={"kontaktname"} value={valueKontakt[`kontaktname`] || ``} onChange={handleChange}/>
                                <label htmlFor="kontaktname" className="label-name">
                        <span className="content-name">
                            Name
                        </span>
                                </label>
                            </div>
                        </div>
                        <div className="div-animated">
                        <div className="kontaktfield__container animated">
                            <input type="email" name="kontaktemail" className="" autoComplete={"off"} id="kontaktemail" value={valueKontakt[`kontaktemail`] || ``} onChange={handleChange}/>
                            <label htmlFor="kontaktemail" className="label-name">
                        <span className="content-name">
                            E-Mail
                        </span>
                            </label>

                        </div>
                        </div>                        <div className="div-animated">

                    <div className="kontaktfield__container area animated">
                            <span  className="textarea kontakt__input" onBlur={(e) => {handleEmptyTextarea(e)}} name="kontaktmessage" id="kontaktnachricht" role="textbox" contentEditable></span>
                            <label htmlFor="kontaktmessage" className="label-name">
                        <span className="content-name">
                            {metadata.message}
                        </span>
                            </label>

                        </div>
                    </div>
                        <p className="serverresponse" ref={serverResponse}></p>
                        <div className="kontakt__btn__container animated">
                            <button type="submit" value="Absenden" className="btn btn-red btn-kontakt hover-moveup">
                                {metadata.absenden}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>)
}

export default localize(ContactPage)

/*
<label className="kontakt__label">
                        <p className="clash small ">*E-Mail:</p>
                        <input type="email" name="kontaktemail" className="kontakt__input" id="kontaktemail" value={valueKontakt[`kontaktemail`] || ``} onChange={handleChange}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="clash small ">*Nachricht:</p>
                        <span className="textarea kontakt__input clash" id="kontaktnachricht" role="textbox" contentEditable></span>
                    </label>
                    <div className="kontakt__btn__container">
                        <button type="submit" value="Absenden" className="btn bg-dark color-light clash">
                            Absenden
                        </button>
                    </div>

                    <label className="kontakt__label">
                        <input type="text" name={"kontaktname"} className={"kontakt__input"} autocomplete={"off"} id={"kontaktname"} value={valueKontakt[`kontaktname`] || ``} onChange={handleChange}/>
                        <p className="kontakt__label__field">Name</p>
                    </label>
 */
